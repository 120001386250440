import React, { useEffect } from 'react'
import Hero from './Hero'
import Body from './Body'

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  return (
    <>
    <Hero />
    <Body />
    </>
  )
}

export default Home