import React from "react";
import Navlink from "./Navlink";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

export default function LandingPage() {
  return (
    <div className="landingpage-container">
      <Navlink />
      <Outlet />
      <Footer />
    </div>
  );
}
