import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../css/nav-response.css";

import "../../css/landing/landing.scss";

export default function Navlink() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMobileMenuToggle = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <div className="navlink">
      <div>
        <Link to="/">
          <div className="logo">
            <img src="./images/logo.svg" alt="Logo" />
          </div>
        </Link>
      </div>

      <div
        className={`nav-link ${showMobileMenu ? "show-mobile" : "hide-mobile"}`}
      >
        <div className="list">
          <ol>
            <li>
              <a href="/#about">About Us</a>
            </li>
            <li>
              <NavLink to="privacy-policy">Privacy Policy</NavLink>
            </li>
            <li>
              <a href="/#support">Support</a>
            </li>
            <li>
              <a
                href="https://ambassador.excelmind.org/amb-create-account"
                className="amb-link"
              >
                Become an Ambassador
              </a>
            </li>
            <li>
              <a
                href="https://schoolhome.excelmind.org/"
                className="school-link"
                target={"_blank"}
              >
                School
              </a>
            </li>
          </ol>
        </div>
        <div className="sign-in-up items">
          <Link to="https://student.excelmind.org/Signin">
            <button className="sign-in">Sign In</button>
          </Link>
          <Link to="https://student.excelmind.org/create-account">
            <button>Create Account</button>
          </Link>
        </div>
      </div>

      <div className={`mobile-menu-toggle`} onClick={handleMobileMenuToggle}>
        <span
          className={`hamburger-bar ${showMobileMenu ? "cross" : ""}`}
        ></span>
        <span
          className={`hamburger-bar ${showMobileMenu ? "cross" : ""}`}
        ></span>
        <span
          className={`hamburger-bar ${showMobileMenu ? "cross" : ""}`}
        ></span>
        <span className="cross-bar1"></span>
        <span className="cross-bar2"></span>
      </div>
    </div>
  );
}
