import React, { useEffect, useState } from "react";
import { CgPhone } from "react-icons/cg";
import { BsEnvelopeFill } from "react-icons/bs";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

const contents = [
  {
    id: 1,
    head: "What is the site all about?",
    body: "Excelmind is an educational platform that provides past questions from some of the most popular exams in Nigeria and internationally. ",
  },
  {
    id: 2,
    head: "Can I share my email and password with someone else?",
    body: "No, it is not recommended to share your personal login information with others. Each account is intended for individual use only.",
  },

  {
    id: 4,
    head: "How long can I have access to  excelmind?",
    body: "The access period may vary depending on the subscription plan you choose. Please refer to the subscription options on the Excelmind website for more information.",
  },
  {
    id: 5,
    head: "How many courses does excelmind offer for each exam?",
    body: "The number of courses offered for each exam may vary. Please refer to the exam list on the Excelmind website for more information.",
  },
  {
    id: 6,
    head: "What are the types of exam I can access on Excelmind?",
    body: "Excelmind offers past questions from a variety of popular exams in Nigeria and internationally, including university entrance exams, professional certification exams, and more. Please refer to the exam list on the Excelmind website for a full list of exams available.",
  },
  {
    id: 7,
    head: " Can Excelmind get me prepared for a professional exam?",
    body: "Excelmind provides past questions from a variety of professional certification exams, which can be useful as a study resource to help you prepare for the exam. However, it is important to note that it is not a substitute for other study materials and professional guidance.",
  },
];

const FAQs = () => {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const customStyles = {
    width: "100%",
  };

  return (
    <div className="faqs" id="support">
      <div className="container faq">
        <div>
          <h5>Need Help?</h5>
          <p>We are always available to assist you with your inquiries:</p>
          <div className="mt-4">
            <a href="tel:08123456789" className="social">
              <CgPhone />
              <span>+234 81 3450 4663 </span>
            </a>

            <a href="mailto:hello@getripay.com" className="social">
              <BsEnvelopeFill />
              <span>info@excelmind.org</span>
            </a>
          </div>
        </div>

        <div style={customStyles} className="questions">
          <h5>Frequently Asked Questions</h5>
          <div className="excel-accordion">
            {contents.map((c) => (
              <div className="tb" key={c.id}>
                <div className="head" onClick={() => setActiveTab(c.id)}>
                  <h6>{c.head}</h6>
                  {activeTab === c.id ? <FaAngleDown /> : <FaAngleRight />}
                </div>
                {activeTab === c.id && (
                  <div className="body">
                    <p>{c.body}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
