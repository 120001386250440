import React, { useEffect } from "react";
// import "../../css/landing/policy.css";
import "../../css/landing/policy.scss";

export default function Policy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="policy">
      <div className="mini-hero">
        <h1>Privacy Policy</h1>
      </div>

      <div className="container">
        <div className="section">
          <h5>Privacy Policy</h5>
          <p>
            Please read our Privacy Policy carefully as it explains the
            following:
          </p>
          <p>
            In order to use the App, you must first acknowledge and agree to our
            Privacy Policy. You cannot use the App without first accepting our
            Privacy Policy.
          </p>
        </div>

        <div className="section">
          <h5>GENERAL</h5>
          <p>
            We provide the ExcelMind Application. We ("Individual", "us", "we"
            or "our") recognize and understand the importance of the privacy of
            its users ("User", "you" or "your") and wants to respect their
            desire to store and access personal information in a private and
            secure manner. This Privacy Policy applies to our application and
            describes how we manage, store, and utilize your Personal Data
            through its Products{" "}
          </p>
          <p>
            In order to use our app, we require you to consent to the collection
            and processing of your Personal Data before you start using the app.
            If you do not agree with the terms of this Privacy Policy, you may
            not use in any manner the app.{" "}
          </p>
          <p>
            We are committed to protecting the privacy of all its users'
            Personal Data and providing a secure, user-controlled environment
            for the use of the app.
          </p>
        </div>

        <div className="section">
          <h5>PERSONAL DATA YOU PROVIDE</h5>
          <p>
            <span>Account Credentials-</span> In order to use the ExcelMind App
            Services, you are required to log in with an Account Authentication
            Mechanism (Name, and email address) When creating an account,
            ExcelMind stores a hashed version of your Account Authentication
            Mechanism for authentication and/or verification purposes. We use a
            third-party service provider for verification and authentication.
          </p>
          <p>
            <span>Profile Information-</span> When you create an account, you
            choose to provide us with Personal Data about yourself. For example,
            in the ExcelMind App, you may provide your Personal Data, such as
            name, email address, and any other information that is required to
            positively identify the user
          </p>
          <p>
            <span>Photos-</span> If you upload a photo while using the ExcelMind
            App Services, you will be required to permit ExcelMind to access
            your camera and photo gallery, in which case ExcelMind will have
            access to the photos stored in your photo gallery.
          </p>
          <p>
            <span>Location and Distance Information-</span> When you use the
            ExcelMind App, we collect your precise location (e.g. your latitude
            and longitude) ("Location") to determine the distance in feet or
            meters (“Distance Information”). Should you choose not to allow the
            ExcelMind App to access your location, certain features (such as
            displaying nearby user profiles or features that include Live
            Location Sharing) of the ExcelMind Services will not function
            properly. You may also revoke this permission and disable the
            location services on your device. You can do so on an iPhone by
            going to Settings, Privacy, Location Services, ExcelMind; and on
            Android, by going to Settings, ExcelMind App, Permissions, Location.
          </p>
          <p>
            <span>Feedback/Support-</span> If you provide us feedback or contact
            customer support, we collect your name and e-mail address and
            possibly other Personal Data (e.g. IP address), as well as any other
            content that you send to us in order to reply and address any issue.
            If you contact customer support, we also collect and retain certain
            technical diagnostic data, such as your phone model. We may keep
            records of our communication with you, including any complaints we
            receive from you about other users (and from other users about you).
          </p>
        </div>

        <div className="section">
          <h5>GENERAL DATA PROTECTION REGULATION (GDPR)</h5>
          <p>We are a Data Controller of your information.</p>
          <p>
            For legal basis, we are collecting and using the personal
            information described in this Privacy Policy depending on the
            Personal Information we collect and the specific context in which we
            collect the information:
          </p>
          <ul>
            <li>We need to perform a contract with you</li>
            <li>You have given us permission to do so</li>
            <li>
              Processing your personal information is in our legitimate
              interests
            </li>
            <li>We need to comply with the law</li>
          </ul>
          <p>
            We will retain your personal information only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use your information to the extent necessary to comply
            with our legal obligations, resolve disputes, and enforce our
            policies.
          </p>
          <p>
            If you have certain data protection rights, and If you wish to be
            informed what Personal Information we hold about you and if you want
            it to be removed from our systems, please contact us.
          </p>
          <br />
          <p>You are entitled to the following rights under applicable laws:</p>
          <ul>
            <li>
              <span>The right to access:</span> you may at any time request to
              access your personal data. Upon request, we will provide a copy of
              your personal data in a commonly used electronic form
            </li>
            <li>
              <span>The right to rectification:</span> you are entitled to
              obtain rectification of inaccurate personal data and to have
              incomplete personal data completed.
            </li>
            <li>
              <span>The right to erase:</span> under certain circumstances
              (including processing on the basis of your consent), you may
              request us to delete your User Data. Please note that this right
              is not unconditional. Therefore, an attempt to invoke the right
              might not lead to an action from us.
            </li>
            <li>
              <span>The right to object:</span> to certain processing activities
              conducted by us in relation to your personal data, such as our
              processing of your personal data based on our legitimate interest.
              The right to object also applies to the processing of your
              personal data for direct marketing purposes.
            </li>
            <li>
              <span>The right to data portability:</span> you are entitled to
              receive your personal data (or have your personal data directly
              transmitted to another data controller) in a structured, commonly
              used, and machine-readable format.
            </li>
          </ul>
        </div>

        <div className="section">
          <h5>PERMISSIONS REQUIRED</h5>
          <ul>
            <li className="uppercase">internet</li>
            <li className="uppercase">notification</li>
            <li className="uppercase">camera</li>
            <li className="uppercase">gps location</li>
            <li className="uppercase">storage</li>
          </ul>
          <p>
            Link to the privacy policy of third-party service providers used by
            the app:
          </p>
          <ul>
            <li>Apple App Store Services</li>
            <li>Google Play Services</li>
          </ul>
        </div>

        <div className="section">
          <h5>LOG DATA</h5>
          <p>
            We want to inform you that whenever you use our Service, in case of
            an error in the app, we collect data and information (through
            third-party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet Protocol (“IP”)
            address, device name, operating system version, the configuration of
            the app when utilizing our Service, the time and date of your use of
            the Service, and other statistics.
          </p>
        </div>

        <div className="section">
          <h5>YOUR PERSONAL DATA</h5>
          <p>
            We are using a strong backend for our ExcelMind App. In the backend,
            all the content will be stored. All Data will remain safe and
            secure. No single information/data will be sent anywhere.
          </p>
        </div>

        <div className="section">
          <h5>security</h5>
          <p>
            We value your trust in providing us with your Personal Information,
            thus we are striving to use commercially acceptable means of
            protecting it. But remember that no method of transmission over the
            internet, or method of electronic storage is 100% secure and
            reliable, and we cannot guarantee its absolute security
          </p>
        </div>

        <div className="section">
          <h5>CHILDREN’S PRIVACY</h5>
          <p>
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            me with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to take the necessary actions.
          </p>
        </div>

        <div className="section">
          <h5>SERVICE PROVIDERS</h5>
          <p>
            We may employ third-party companies and individuals for the
            following reasons:
          </p>
          <ul>
            <li>To facilitate our Service</li>
            <li>To provide the Service on our behalf</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used</li>
          </ul>
          <p>
            We want to inform users of this Service that these third parties
            have access to your Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose
          </p>
        </div>

        <div className="section">
          <h5>UPDATES OR CHANGES TO OUR PRIVACY POLICY</h5>
          <p>
            Occasionally, we may change or update this Privacy Policy to allow
            us to use or share your previously collected Personal Data for other
            purposes. If we use your Personal Data in a manner materially
            different from that stated at the time of the collection, we will
            provide you with a notice on our Website and in our ExcelMind Mobile
            Application indicating that the Privacy Policy has been changed or
            updated and request you to agree with the updated or changed Privacy
            Policy.
          </p>
        </div>

        <div className="section">
          <h5>contact us</h5>
          <p>info@excelmind.org</p>
        </div>
      </div>
    </section>
  );
}
