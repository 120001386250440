import React from "react";
// import "../../css/landing.css";

export default function Ratings({ star, input, userprofile, username }) {
  return (
    <div className="rating">
      <img src={star} />
      <p>{input} </p>
      <div className="user-profile">
        <img src={userprofile} />
        <p className="username">{username}</p>
      </div>
    </div>
  );
}
